import React, { useState } from 'react';
import { Button, Upload, Select, Modal, Spin, Space, Typography, Flex, message, Form, Input } from 'antd';
import { InboxOutlined, SendOutlined } from '@ant-design/icons';
import AppHeader from '../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Link from 'antd/es/typography/Link';
import { Rate } from 'antd';

const { Option } = Select;

interface DefaultPageProps {
  onLogout: () => void;
}

const DefaultPage: React.FC<DefaultPageProps> = ({ onLogout }) => {
    const [selectedMode, setSelectedMode] = useState<'ai' | 'community' | null>(null);
    const [file, setFile] = useState<any>();
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [cancerType, setCancerType] = useState('Breast');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isWaitListModalVisible, setIsWaitListModalVisible] = useState(false);
    const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
    const [isReferModalVisible, setIsReferModalVisible] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
      onLogout();
      navigate('/signin');
    };
    
    const handleModeChange = (mode: 'ai' | 'community') => {
        setSelectedMode(mode);
    };

    const handleFileChange = (info: any) => {
      setFileUrl('');      
      setFile(info.file);
    };

    const handleSubmit = async () => {
      setFileUrl('');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('cancer_type', cancerType);

      setIsSubmitting(true);

      try {
        const response = await axios.post('https://demo.ultraai.tech/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob'
        });
  
        // Check for response data type
        const blob = response.data as Blob;
        const url = URL.createObjectURL(blob);

        setFileUrl(url);
        setIsModalVisible(true);
      } catch (error) {
        message.error('Upload failed.');
      } finally {
        setIsSubmitting(false);
      }
    };

    const handlePreview = async () => {
      setFileUrl('');

      setIsSubmitting(true);

      try {
        const response = await axios.get('https://demo.ultraai.tech/stream?cancer_type=Liver&demo_name=Demo-1', {
          headers: {
            'Content-Type': 'text/event-stream',
          },
          responseType: 'blob'
        });
  
        // Check for response data type
        const blob = response.data as Blob;
        const url = URL.createObjectURL(blob);

        setFileUrl(url);
        setIsModalVisible(true);
      } catch (error) {
        message.error('Upload failed.');
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
        <div>
            <AppHeader userName="John Doe" onLogout={handleLogout} />
            <Flex vertical align="center">
              <Space direction="vertical" style={{ padding: '50px', textAlign: 'center' }}>
                  <Typography.Title level={3}>Choose either our proprietary technology or a community of radiologists to help diagnose your scan.</Typography.Title>
                  <Space direction="horizontal" size="large">
                    <Button size="large" style={{ width: '300px' }} type={selectedMode === 'ai' ? 'primary' : 'default'} onClick={() => handleModeChange('ai')}>
                      Tumor/Cancer Diagnosis
                    </Button>
                    <Button size="large" style={{ width: '300px' }} type={selectedMode === 'community' ? 'primary' : 'default'} onClick={() => handleModeChange('community')}>
                      Oncology community diagnosis
                    </Button>
                  </Space>

                  {selectedMode === 'ai' && (
                      <Space direction="vertical" style={{ marginTop: '40px' }}>
                          <Upload.Dragger beforeUpload={() => false} multiple={false} showUploadList={false} onChange={handleFileChange}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              {file && file.name
                              ? `File: ${file.name}. You can replace the file by uploading again.`
                              : 'Click or drag file to this area to upload'}
                            </p>
                          </Upload.Dragger>
                          <Link onClick={handlePreview}>Preview</Link>
                          <Select placeholder="Select scan type" size="large" style={{ width: '200px', marginTop: '20px' }} value={cancerType} onChange={(value) => setCancerType(value)}>
                              <Option value="Breast">Breast</Option>
                              <Option value="Liver">Liver</Option>
                          </Select>

                          <Button style={{ width: '200px', marginTop: '20px' }} size="large" type="primary" onClick={handleSubmit}>
                              Submit
                          </Button>
                      </Space>
                  )}

                  {selectedMode === 'community' && (
                    <>
                      <Typography.Title level={5} style={{ marginTop: '40px' }}>
                        UltraAI Expert community is in building...
                        <br />
                        Please join the <a style={{ textDecoration: 'underline' }} onClick={() => setIsWaitListModalVisible(true)}>wait list</a> or contact the UltraAI team!
                      </Typography.Title>
                      <Typography.Paragraph style={{ marginTop: '20px' }}>
                        Share your thoughts about the **Oncology Community Diagnosis** feature by leaving your{' '}
                        <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setIsCommentModalVisible(true)}>
                          comments
                        </a>.
                      </Typography.Paragraph>
                    </>
                  )}
              </Space>
            </Flex>
            <div 
              style={{
                position: 'fixed',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                textAlign: 'center',
              }}
            >
              <Button
                type="primary"
                size="large"
                style={{
                  fontSize: '20px',
                  padding: '10px 30px',
                  height: 'auto',
                }}
                onClick={() => setIsReferModalVisible(true)}
              >Refer a Fellow Radiologist
              </Button>
            </div>
            <Modal open={isModalVisible} maskClosable={false} onCancel={() => setIsModalVisible(false)} footer={null}>
              <Typography.Title level={3} style={{ textAlign: 'center' }}>
                AI Diagnosed Insight
              </Typography.Title>
              <div style={{ marginTop: '20px' }}>
                {fileUrl && (file.name.endsWith('.mp4') || file.name.endsWith('.avi')) ? (
                  <video src={fileUrl} controls style={{ width: '100%' }} autoPlay />
                ) : 
                  fileUrl ? <img src={fileUrl ?? ''} alt="Processed" style={{ width: '100%' }} /> : <Typography.Title level={4} style={{ textAlign: 'center' }}>No result</Typography.Title>
                }
              </div>
            </Modal>
            <Modal open={isWaitListModalVisible} maskClosable={false} onCancel={() => setIsWaitListModalVisible(false)} footer={null}>
              <Typography.Title level={3} style={{ textAlign: 'center' }}>
                Submit
              </Typography.Title>
              <Form
                  name="wait_list"
                  layout="vertical"
                  requiredMark={false}
                  style={{ marginTop: '20px' }}
              >
                  <Form.Item
                      name="name"
                      label="Name"
                      rules={[{ required: true, message: 'Please input your name!', type: 'string' }]}
                  >
                      <Input size="large" />
                  </Form.Item>
                  <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true, message: 'Please input your title!', type: 'string' }]}
                  >
                      <Input size="large" />
                  </Form.Item>
                  <Form.Item
                      name="email"
                      label="Email"
                      rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                  >
                      <Input size="large" type="email" />
                  </Form.Item>
                  <Form.Item
                      name="company"
                      label="Company"
                      rules={[{ required: true, message: 'Please input your company!', type: 'string' }]}
                  >
                      <Input size="large" />
                  </Form.Item>
                  <Form.Item
                      name="interest"
                      label="What are you interested in?"
                      rules={[{ required: true, message: 'Please input your interest!', type: 'string' }]}
                  >
                      <Input.TextArea size="large" rows={3} />
                  </Form.Item>
                  <Button type="primary" block>Submit</Button>
              </Form>
            </Modal>
            <Modal
              open={isCommentModalVisible}
              maskClosable={false}
              onCancel={() => setIsCommentModalVisible(false)}
              footer={null}
            >
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <Typography.Title level={4}>Share Your Feedback</Typography.Title>

                <div style={{ marginTop: '20px', textAlign: 'left' }}>
                  <Typography.Text>Functionality:</Typography.Text>
                  <div style={{ margin: '10px 0' }}>
                    <Rate style={{ fontSize: '24px' }} />
                  </div>
                </div>

                <div style={{ marginTop: '20px', textAlign: 'left' }}>
                  <Typography.Text>Ease of Use:</Typography.Text>
                  <div style={{ margin: '10px 0' }}>
                    <Rate style={{ fontSize: '24px' }} />
                  </div>
                </div>

                <div style={{ marginTop: '20px', textAlign: 'left' }}>
                  <Typography.Text>Performance:</Typography.Text>
                  <div style={{ margin: '10px 0' }}>
                    <Rate style={{ fontSize: '24px' }} />
                  </div>
                </div>

                <div style={{ marginTop: '20px', textAlign: 'left' }}>
                  <Typography.Text>Accessibility:</Typography.Text>
                  <div style={{ margin: '10px 0' }}>
                    <Rate style={{ fontSize: '24px' }} />
                  </div>
                </div>

                <Form layout="vertical" style={{ marginTop: '20px' }}>
                  <Form.Item name="moreDetails" label="More details">
                    <Input.TextArea rows={4} placeholder="Provide more feedback here..." />
                  </Form.Item>
                  <Button type="default" size="large" block>
                    Submit
                  </Button>
                </Form>
              </div>
            </Modal>
            <Modal open={isReferModalVisible} maskClosable={false} onCancel={() => setIsReferModalVisible(false)} footer={null}>
              <Form
                  name="refer_form"
                  layout="vertical"
                  requiredMark={false}
                  style={{ marginTop: '20px' }}
              >
                <Typography.Title level={3}>Referral Program</Typography.Title>
                <Typography.Text style={{ textAlign: 'start' }}>
                  When your referred expert joins our platform, you will automatically receive 20 FREE gift scan credits. Feel free to upload up to 20 scans and get reliable cancer-related insights instantaneously.
                </Typography.Text>
                  <Form.Item
                      name="referred_name"
                      label="Name of a referred person"
                      rules={[{ required: true, message: 'Please input name of referring person!', type: 'string' }]}
                      style={{ marginTop: '20px' }}
                  >
                      <Input size="large" />
                  </Form.Item>
                  <Form.Item
                      name="referred_email"
                      label="Email of the referred person"
                      rules={[{ required: true, message: 'Please input email of referring person!', type: 'email' }]}
                  >
                      <Input size="large" type="email" />
                  </Form.Item>
                  <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true, message: 'Please input your title!', type: 'string' }]}
                  >
                      <Input size="large" />
                  </Form.Item>
                  <Form.Item
                      name="name"
                      label="Who is referring this person"
                      rules={[{ required: true, message: 'Please input your name!', type: 'string' }]}
                  >
                      <Input size="large" defaultValue="Hayk Ha" />
                  </Form.Item>

                  <div style={{ textAlign: 'center' }}>
                    <Typography.Paragraph
                      id="referral-text"
                      style={{
                        fontSize: '16px',
                        padding: '15px',
                        borderRadius: '8px',
                        textAlign: 'left',
                        whiteSpace: 'pre-wrap',
                        paddingBottom: '0px'
                      }}
                    >
                      CEO: Hayk Ha
                      <br />
                      Email: hayk@ultraai.hub
                      <br/>
                      Phone: +65 9754 7744
                      <br />
                      Linkedin: https://www.linkedin.com/in/ultraai-hub
                      <br />
                    </Typography.Paragraph>
                    <Button
                      type="default"
                      size="large"
                      icon={<SendOutlined />} // Add FontAwesome or similar for icons
                      style={{
                        marginTop: '10px',
                        marginBottom: '20px',
                        padding: '0 15px',
                        height: '40px',
                        fontSize: '16px',
                      }}
                      onClick={() => {
                        const textToCopy = document.getElementById('referral-text')?.innerText || '';
                        navigator.clipboard.writeText(textToCopy).then(
                          () => {
                            message.success('Copied');
                          },
                          () => {
                            message.error('Failed to copy');
                          }
                        );
                      }}
                    >
                      Copy
                    </Button>
                  </div>

                  <Button type="default" size="large" block>Submit</Button>
              </Form>
            </Modal>
            {isSubmitting && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default DefaultPage;
