// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text color */
}

.container {
  background-color: #001f3f; /* Dark blue background for a specific container */
  padding: 20px;
}

#root {
  height: 100%;
}

h2 {
  color: #001529;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,8JAA8J;EAC9J,yBAAyB,EAAE,qBAAqB;EAChD,cAAc,EAAE,qBAAqB;AACvC;;AAEA;EACE,yBAAyB,EAAE,kDAAkD;EAC7E,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  background-color: #000000; /* Black background */\n  color: #ffffff; /* White text color */\n}\n\n.container {\n  background-color: #001f3f; /* Dark blue background for a specific container */\n  padding: 20px;\n}\n\n#root {\n  height: 100%;\n}\n\nh2 {\n  color: #001529;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
