import React from 'react';
import { Layout, Image } from 'antd';
import UserAvatar from './UserAvatar';

const { Header } = Layout;

const AppHeader: React.FC<{ userName: string, onLogout: () => void }> = ({ userName, onLogout }) => {
    return (
        <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px', background: '#001529' }}>
            <div style={{ color: 'white', fontSize: '20px' }}>
              <Image
                className="w-full h-full py-[30px] cursor-pointer"
                alt="logo"
                preview={false}
                src={'/UltraAI_logo.png'}
                height={30}
                style={{
                  objectFit: 'contain',
                }}
              />
            </div>
            <UserAvatar name={userName} onLogout={onLogout} />
        </Header>
    );
};

export default AppHeader;
