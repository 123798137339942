import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import DefaultPage from './pages/DefaultPage';
import { ConfigProvider, theme } from 'antd';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  useEffect(() => {
    // Check if token (user data) exists in localStorage
    const token = localStorage.getItem('userToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleSignIn = (userData: any) => {
    // Mock sign-in logic
    localStorage.setItem('userToken', JSON.stringify(userData));
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    // Clear the token from localStorage and update the state
    localStorage.removeItem('userToken');
    setIsAuthenticated(false);
  };

  const darkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: '#001f3f', // Dark blue
      colorLink: '#8FCBF3',
      colorTextBase: '#ffffff', // White text color
      colorBgBase: '#201f3f', // Dark blue background
      colorBorder: '#003366', // Darker blue for borders
      colorBgContainer: '#002a40', // Darker background for containers
      colorInputBg: '#002a40', // Darker background for input fields,
      // Custom button text color and hover color
      colorTextButton: '#ffffff', // Button text color
      colorTextButtonHover: '#8FCBF3', // Button text color on hover
      colorText: '#8FCBF3'
    },
    components: {
      Button: {
        defaultHoverColor: '#8FCBF3',
      },
      Spin: {
        colorPrimary: '#8FCBF3'
      },
      Select: {
        optionSelectedBg: '#003366'
      }
    }
  }

  return (
    <ConfigProvider
      theme={darkTheme}
    >
      <Router>
        <Routes>
          {/* Redirect to DefaultPage if authenticated, otherwise go to SignIn */}
          <Route 
            path="/" 
            element={isAuthenticated ? <DefaultPage onLogout={handleLogout} /> : <Navigate to="/signin" />} 
          />
          <Route path="/signin" element={<SignIn onSignIn={handleSignIn} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
