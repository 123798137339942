import React, { useState } from 'react';
import { Button, Upload, Select, Modal, Spin, Space, Typography, Flex, message } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import AppHeader from '../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const { Option } = Select;

interface DefaultPageProps {
  onLogout: () => void;
}

const DefaultPage: React.FC<DefaultPageProps> = ({ onLogout }) => {
    const [selectedMode, setSelectedMode] = useState<'ai' | 'community' | null>(null);
    const [file, setFile] = useState<any>();
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [cancerType, setCancerType] = useState('Breast');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
      onLogout();
      navigate('/signin');
    };
    
    const handleModeChange = (mode: 'ai' | 'community') => {
        setSelectedMode(mode);
    };

    const handleFileChange = (info: any) => {
      setFileUrl('');      
      setFile(info.file);
    };

    const handleSubmit = async () => {
      setFileUrl('');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('cancer_type', cancerType);

      setIsSubmitting(true);

      try {
        const response = await axios.post('https://demo.ultraai.tech/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob'
        });
  
        // Check for response data type
        const blob = response.data as Blob;
        const url = URL.createObjectURL(blob);

        setFileUrl(url);
        setIsModalVisible(true);
      } catch (error) {
        message.error('Upload failed.');
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
        <div>
            <AppHeader userName="John Doe" onLogout={handleLogout} />
            <Flex vertical align="center">
              <Space direction="vertical" style={{ padding: '50px', textAlign: 'center' }}>
                  <Typography.Title level={3}>Please select AI engine or expert in the community to diagnose your UltraAI Scan.</Typography.Title>
                  <Space direction="horizontal" size="large">
                    <Button size="large" style={{ width: '200px' }} type={selectedMode === 'ai' ? 'primary' : 'default'} onClick={() => handleModeChange('ai')}>
                        AI Diagnosis
                    </Button>
                    <Button size="large" style={{ width: '200px' }} type={selectedMode === 'community' ? 'primary' : 'default'} onClick={() => handleModeChange('community')}>
                        Community Diagnosis
                    </Button>
                  </Space>

                  {selectedMode === 'ai' && (
                      <Space direction="vertical" style={{ marginTop: '40px' }}>
                          <Upload.Dragger beforeUpload={() => false} multiple={false} showUploadList={false} onChange={handleFileChange}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              {file && file.name
                              ? `File: ${file.name}. You can replace the file by uploading again.`
                              : 'Click or drag file to this area to upload'}
                            </p>
                          </Upload.Dragger>

                          <Select placeholder="Select scan type" size="large" style={{ width: '200px', marginTop: '20px' }} value={cancerType} onChange={(value) => setCancerType(value)}>
                              <Option value="Breast">Breast</Option>
                              <Option value="Liver">Liver</Option>
                          </Select>

                          <Button style={{ width: '200px', marginTop: '20px' }} size="large" type="primary" onClick={handleSubmit}>
                              Submit
                          </Button>
                      </Space>
                  )}

                  {selectedMode === 'community' && (
                      <Typography.Title level={5} style={{ marginTop: '40px' }}>
                        UltraAI Expert community is in building...
                        <br />
                        Please join the wait list or contact the UltraAI team!
                      </Typography.Title>
                  )}
              </Space>
            </Flex>
            <Modal open={isModalVisible} maskClosable={false} onCancel={() => setIsModalVisible(false)} footer={null}>
              <Typography.Title level={3} style={{ textAlign: 'center' }}>
                AI Diagnosed Insight
              </Typography.Title>
              <div style={{ marginTop: '20px' }}>
                {fileUrl && (file.name.endsWith('.mp4') || file.name.endsWith('.avi')) ? (
                  <video src={fileUrl} controls style={{ width: '100%' }} autoPlay />
                ) : 
                  fileUrl ? <img src={fileUrl ?? ''} alt="Processed" style={{ width: '100%' }} /> : <Typography.Title level={4} style={{ textAlign: 'center' }}>No result</Typography.Title>
                }
              </div>
            </Modal>

            {isSubmitting && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default DefaultPage;
