import React from 'react';
import { Avatar, Dropdown, Menu, MenuProps } from 'antd';

const UserAvatar: React.FC<{ name: string, onLogout: () => void }> = ({ name, onLogout }) => {
    const initial = name.slice(0, 2).toUpperCase();

    const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
      if (key === 'logout') {
        onLogout();
      }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="logout">Logout</Menu.Item>
        </Menu>
    );

    const items: MenuProps['items'] = [
      {
        label: 'Logout',
        key: 'logout',
      },
    ];

    return (
        <Dropdown menu={{ items, onClick: handleMenuClick }}>
            <Avatar style={{ backgroundColor: '#87d068', cursor: 'pointer' }}>
                {initial}
            </Avatar>
        </Dropdown>
    );
};

export default UserAvatar;
